<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
        <div class="card-header">
            <h4>
                المهام <button v-b-modal.new @click="point=0" style="border-radius: 50%; width: 30px; height: 30px; padding-right: 9px" class="btn btn-sm btn-primary">
                    <i class="fa fa-plus"></i>
                </button>
            </h4>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                            العنوان
                        </th>
                        <th>
                            الفصول
                        </th>
                        <th>
                            تاريخ الانتهاء
                        </th>
                        <th>
                            حذف
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="r in rates" :key="r._id">
                            <td>
                                {{ r.title }}
                            </td>
                            <td>
                                {{ r.classrooms }}
                            </td>
                            <td>
                                {{ r.enddate }}
                            </td>
                            <td>
                                <button class="btn btn-outline-danger btn-sm" @click="remove(r._id)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="new" title="المهام" hide-footer>
        <div class="alert alert-success text-center g">
            اضافة المهام متاحة من التطبيق فقط حالياً.
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BModal
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            oo: [
                "1"
            ],
            students: [],
            selected: "",
            q: "",
            rate: "",
            rates: [],
            point: 0,
            ss: {
                1: [
                    "مشاركة",
                  "حل تمارين الفصل",
                  "كتابة مع المعلم",
                  "مميز",
                  "متعاون",
                  "مؤدب",
                  "حفظ المطلوب",
                  "متفاعل في مدرستي",
                  "حل الواجب"
            ], 2: [
                "تأخر عن الحصة",
                  "نسيان الكتاب",
                  "نوم اثناء الشرح",
                  "حديث جانبي",
                  "عدم الانتباه للدرس",
                  "الاكل والشرب داخل الفصل",
                  "مضايقة زميل",
                  "اعاقة سير الدرس",
                  "اتأذان لدورة المياه",
                  "تحصيلي",
                  "عدم المشاركة",
                  "عدم حل الواجب",
                  "عدم الكتابة مع المعلم",
                  "ضعف في الاختبارات",
            ]
            },
            teacher: JSON.parse(localStorage.getItem('teacher')),
        }
    },
    created(){
        var g = this;
        g.getStudents()
        g.getRates()
    },
    methods: {
        remove(r){
            if(confirm("متأكد من الحذف؟")){
                var g = this;
                $.post(api + '/teacher/tasks/delete', {
                    username: this.teacher.username,
                    password: this.teacher.password,
                    id: r
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getRates()
                    }
                }).fail(function(){
                })
            }
        },
        getRates(){
            var g = this;
            $.post(api + '/teacher/tasks', {
                username: this.teacher.username,
                password: this.teacher.password,
                q: this.q
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.rates = r.response;
                }
            }).fail(function(){
            })
        },
    }
}
</script>

<style>

</style>